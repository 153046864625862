<template>
  <div v-if="audio && audio.url" class="c-read-article">
    <audio ref="audioplayer" class="c-read-article__player">
      <source :src="audio.url" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
    <TheButton
      v-if="isAudioPaused"
      text
      @click="onPlayAudio"
      class="c-read-article__play"
    />
    <TheButton v-else text @click="onPlayAudio" class="c-read-article__pause" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useAssets } from '@/core'
import TheButton from '@/components/TheButton'

export default {
  name: 'ReadArticle',

  components: {
    TheButton,
  },

  props: {
    content: Object,
  },

  setup(props) {
    const audio = ref(null)
    const audioplayer = ref(null)

    const { fetchAsset, getAssetById } = useAssets()

    const isAudioPaused = ref(true)

    const onPlayAudio = () => {
      if (isAudioPaused.value) {
        audioplayer.value.play()
        isAudioPaused.value = false
      } else {
        audioplayer.value.pause()
        isAudioPaused.value = true
      }
    }

    onMounted(async () => {
      if (props.content) {
        await fetchAsset(props.content.audioId)
        audio.value = getAssetById(props.content.audioId)
      }
    })

    return {
      audio,
      audioplayer,
      isAudioPaused,
      onPlayAudio,
    }
  },
}
</script>
