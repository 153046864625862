<template>
  <nav aria-label="Breadcrumb" class="c-breadcrumbs">
    <ul class="c-breadcrumbs__list">
      <li class="c-breadcrumbs__item">
        <router-link class="c-breadcrumbs__link c-breadcrumbs__link--home" to="/">
          <span>
            {{ $t('NAV_HOME') }}
          </span>
        </router-link>
      </li>
      <li class="c-breadcrumbs__item" v-for="item in breadcrumbs" :key="item.name">
        <router-link v-if="item.to" class="c-breadcrumbs__link" :to="item.to"><span class="c-breadcrumbs__text">{{ item.name }}</span></router-link>
        <span v-else aria-current="page">{{ item.name }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useBreadcrumbs } from '@/core'

export default {
  name: 'Breadcrumbs',

  setup() {
    const { breadcrumbs } = useBreadcrumbs()

    return {
      breadcrumbs
    }
  }
}
</script>
