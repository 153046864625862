<template>
  <div class="v-single-content">
    <div class="v-single-content__wrapper">
      <Breadcrumbs />

      <div class="v-single-content__header">
        <ReadArticle v-if="readArticle" :content="readArticle" />
        <TheHeading level="h1">
          {{ content.name }}
        </TheHeading>
      </div>
      <div
        v-if="!loading"
        :class="[
          `v-single-content__content`,
          { 'v-single-content__content--hide-audio-section': readArticle },
        ]"
      >
        <ContentRenderer
          v-if="contentItems.length"
          :content-items="contentItems"
          :has-toc="true"
        />
      </div>
      <div v-else class="v-single-content__content-loader-wrapper">
        <ContentLoader viewBox="0 0 400 160">
          <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
          <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
          <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
          <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
          <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
          <rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
        </ContentLoader>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { ContentLoader } from 'vue-content-loader'
import {
  useBreadcrumbs,
  useStructures,
  useTenant,
  useLocalizations,
} from '@/core'

import useStructuresLocal from '@/composables/useStructures'
import useTranslations from '@/composables/useTranslations'
import Breadcrumbs from '@/components/Breadcrumbs'
import TheHeading from '@/components/TheHeading'
import ReadArticle from '@/components/ReadArticle'
export default {
  name: 'SingleContent',

  components: {
    Breadcrumbs,
    TheHeading,
    ReadArticle,
    ContentLoader,
  },

  setup() {
    const loading = ref(false)
    const reader = ref(null)
    const { locale } = useI18n()
    const { isFallbackLanguage, gqlSingleContent, getTenantTranslation } =
      useTranslations()
    const { fetchStructuresLocalization } = useLocalizations()
    const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()
    const { tenant } = useTenant()
    const route = useRoute()
    const {
      getStructuresBySlugPath,
      fetchStructuresNode,
    } = useStructures()
    const { getStructuresChildrens } = useStructuresLocal()
    const content = computed(() => {
      return getStructuresBySlugPath(
        contentSlug.value,
        false,
        'DIRECTORY',
        locale.value,
      )
    })
    const parent = computed(() => {
      return getStructuresBySlugPath(
        parentSlug.value,
        false,
        'DIRECTORY',
        locale.value,
      )
    })
    const contentItems = computed(() => {
      const contentTree = getStructuresChildrens(
        content.value.id,
        false,
        locale.value,
      ).map((child) => {
        if (child.type === 'DIRECTORY') {
          child.contentData = getStructuresChildrens(
            child.id,
            false,
            locale.value,
          )
          child.contentType = 'section'
        }
        return child
      })
      return contentTree
    })

    const readArticle = ref(null)

    const parentSlug = computed(
      () =>
        `${tenant.value.slug}/${
          route.params.content && route.params.content[0]
        }`,
    )
    const contentSlug = computed(
      () =>
        `${tenant.value.slug}/${
          route.params.content && route.params.content.join('/')
        }`,
    )
    useHead({
      title: computed(
        () =>
          `${content.value && content.value.name} - ${getTenantTranslation(
            tenant.value.name,
          )}`,
      ),
    })
    /* TBA: Build slugpaths for breadcrumbs from /content/ param */

    onMounted(async () => {
      loading.value = true
      clearBreadcrumbs()
      await fetchStructuresNode(contentSlug.value)
      await fetchStructuresNode(parentSlug.value)
      const gqlRes = await gqlSingleContent(content.value.id)
      try {
        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(content.value.id, locale.value)
          await fetchStructuresLocalization(parent.value.id, locale.value)
        }
      } catch {
        //
      }
      setBreadcrumbs([
        {
          name: parent.value.name,
          to: `/${route.path.split('/')[1]}/${route.params.content[0]}`,
        },
        {
          name: content.value.name,
        },
      ])
      // Our graphql service doesn't allow us to query deep nested data
      await Promise.all(
        gqlRes.data.structure.data.children.data.map(async (ch) => {
          if (ch.type === 'DIRECTORY') {
            await gqlSingleContent(ch.id)
          }
          if (ch.contentType === 'audio') {
            readArticle.value = ch.content.data.content
          }
        }),
      )
      loading.value = false
    })

    return {
      reader,
      loading,
      content,
      contentItems,
      readArticle,
    }
  },
}
</script>
